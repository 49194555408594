@import '~style';

$block-name: 'b-about-points';

.#{$block-name} {
	margin-top: 45px;
	margin-bottom: 45px;
	padding: 0 30px;

	&__container {
		@include blockContainer;

		max-width: 1400px;
		background-color: $color-items;
	}

	&__items {
		padding: 45px 0 50px;
		margin: 0 auto;
		max-width: 1100px;

		@include breakpoint(large) {
			display: flex;
			justify-content: space-between;
		}
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 30px;
		}

		@include breakpoint(large) {
			margin-bottom: 0;
			width: calc(33.33% - 20px);
		}
	}

	&__number {
		font-size: 18px;
		font-weight: 600;
		color: $color-link-blue;

		@include breakpoint(large) {
			height: 100%;
			max-height: 100px;
		}

		@include breakpoint(xlarge) {
			max-height: 75px;
		}
	}

	&__line {
		width: 95px;
		height: 2px;
		background-color: $color-green-blue;
		display: block;
		margin: 15px 0;
	}

	&__text {
		font-size: 16px;

		@include breakpoint(medium) {
			max-width: 200px;
		}
	}
}
